<template>
  <div class="disqualify-container">
    <h2>Our apologies. Based on your answers, you do not qualify for our service.</h2>
  </div>
</template>

<style scoped lang="scss">
.disqualify-container {
  padding: 60px 10px;
  h2 {
    text-align: center;
    font-family: "Libre Franklin", sans-serif;
  }
}
</style>