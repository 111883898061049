<template>
  <main>
    <NavBar />
    <DisqualifyContent />
  </main>
  <Footer />
</template>

<script setup>
import NavBar from "../components/NavBar";
import Footer from "../components/TheFooter";
import DisqualifyContent from "../components/DisqualifyContent";

window.scrollTo({top: 0, behavior: 'smooth'});
</script>